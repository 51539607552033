<template>
  <div class='main'>
    <div class="topbar">
      <Button @click.stop="emptyRecycle"
              type="primary">
        <Icon class="m-r-5"
              custom="i-icon icon-delete"
              size="16"/>
        清空回收站
      </Button>
      <div class="batch"
           v-show="selectArr.length>0">
        <Button @click.stop="batchRestore"
                class="batchBtn m-l-20">
          <Icon custom="i-icon icon-restore"
                size="16"/>
          还原
        </Button>
        <Button @click.stop="batchDelete"
                class="batchBtn m-l-20">
          <Icon custom="i-icon icon-delete"
                size="16"/>
          彻底删除
        </Button>
      </div>
    </div>
    <div class="list">
      <DiskTableComponent :columns="columns"
                          :data="data"
                          @on-selection-change="onSelectionChange"
                          ref="table">
        <template slot="operation"
                  slot-scope="{ row,index }">
          <div class="operation">
            <Icon @click.stop="onClickRestore(row)"
                  class="m-r-10"
                  custom="i-icon icon-restore"
                  size="20"
                  title="还原"/>
            <Icon @click.stop="onClickDelete(row)"
                  custom="i-icon icon-delete"
                  size="20"
                  title="删除"/>
          </div>
        </template>
      </DiskTableComponent>
      <infinite-loading :distance="200"
                        :identifier="manualResetState"
                        @infinite="getRecycleList"
                        ref="infiniteLoading">
        <div class="total"
             slot="no-more">共{{data.length}}项
        </div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import {deleteRecycle, emptyRecycle, getRecycleList, restoreRecycle} from '../../services/diskapi'
import DiskTableComponent from '../../components/disk/DiskTableComponent'

export default {
  components: {DiskTableComponent},
  data () {
    return {
      columns: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '文件名称',
          key: 'name',
          minWidth: 200,
          tooltip: true,
          slot: 'name'
        },
        {
          title: '删除时间',
          key: 'deleteTime',
          width: 180,
        },
        {
          title: '文件大小',
          key: 'size',
          width: 180,
          render: (h, params) => {
            let size = params.row.size
            let text = size ? this.$formatFileSize(size) : ''
            return h('span', text)
          }
        },
        {
          title: '操作人',
          key: 'delUserName',
          width: 180,
        },
        {
          title: '操作',
          slot: 'operation',
          width: 140
        }
      ],
      data: [],
      selectArr: [],
      pageNum: 1,
      pageSize: 20,
      isMore: true,
      manualResetState: false,//手动重置触底加载状态
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 重置列表数据
    resetListData () {
      this.pageNum = 1
      this.data = []
      this.selectArr = []
      this.isMore = true
      this.manualResetState = !this.manualResetState
    },
    // 获取文件列表
    getRecycleList ($state) {
      if (!this.isMore) return
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      getRecycleList(params).then(res => {
        if (res.code === 0) {
          $state.loaded()
          let list = res.data.list.map(child => {
            let deleteUserObj = child.deleteUser
            child.delUserName = deleteUserObj.name || ''
            child.url = child.path
            child.objType = child.type
            return child
          })
          if (list.length === 0) {
            this.isMore = false
            $state.complete()
            return
          }
          list.forEach(item => {
            item.isCheck = false
          })
          this.data = this.data.concat(list)
          if (res.data.pages === params.pageNum) {
            this.isMore = false
            $state.complete()
          } else {
            this.pageNum++
          }
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 选择改变
    onSelectionChange (selection) {
      this.selectArr = selection
    },
    // 点击还原
    onClickRestore (row) {
      this.onRestore([row])
    },
    // 批量还原
    batchRestore () {
      this.onRestore(this.selectArr)
    },
    // 还原回收站
    onRestore (list) {
      this.$Modal.confirm({
        title: '还原',
        content: '<p>确认要还原文件？</p>',
        onOk: () => {
          let recycleList = list.map(item => {
            return {
              id: item.id
            }
          })
          restoreRecycle(recycleList).then(res => {
            if (res.code === 0) {
              this.$Message.info('还原成功!')
              this.resetListData()
            } else {
              if (res.msg) this.$Message.info(res.msg)
            }
          })
        }
      })
    },
    // 点击删除
    onClickDelete (row) {
      this.onDelete([row])
    },
    // 点击批量删除
    batchDelete () {
      this.onDelete(this.selectArr)
    },
    // 彻底删除回收站
    onDelete (list) {
      this.$Modal.confirm({
        title: '彻底删除',
        content: '<p>文件将被彻底删除，无法恢复。<br>确定要删除文件？</p>',
        onOk: () => {
          let recycleIdList = list.map(item => item.id)
          deleteRecycle({
            idList: recycleIdList.join(',')
          }).then(res => {
            if (res.code === 0) {
              this.$Message.info('删除成功!')
              this.resetListData()
            } else {
              if (res.msg) this.$Message.info(res.msg)
            }
          })
        }
      })
    },
    // 清空回收站
    emptyRecycle () {
      this.$Modal.confirm({
        title: '清空回收站',
        content: '<p>确定要清空回收站？</p>',
        onOk: () => {
          emptyRecycle().then(res => {
            if (res.code === 0) {
              this.$Message.info('清空成功!')
              this.resetListData()
            } else {
              if (res.msg) this.$Message.info(res.msg)
            }
          })
        }
      })
    }
  }
}

</script>
<style lang='scss' scoped>
  .main {
    width: 100%;
    height: 100%;
    padding-left: 30px;

    .topbar {
      display: flex;
      align-items: center;
      padding: 20px 20px 20px 0;
    }

    .list {
      height: 100%;
      overflow-y: scroll;

      .total {
        font-size: 14px;
        color: #666;
        margin-top: 20px;
      }

      .operation {
        display: flex;
        align-items: center;

        ::v-deep .ivu-icon {
          cursor: pointer;
        }
      }
    }
  }
</style>
