<template>
  <div>
    <div class="selectHeader"
         v-if="showHeader&&selectArr.length>0">
      <Checkbox :disabled="data.length===0"
                :value="isCheckAll"
                @on-change="onChangeCheckAll"></Checkbox>
      <span>已选中{{selectArr.length}}项/共{{data.length}}项</span>
    </div>
    <Table :columns="columns"
           :data="data"
           :show-header="showHeader&&selectArr.length===0"
           @on-row-dblclick="onRowDblclick"
           class="table"
           highlight-row
           ref="table">
      <template slot="checkbox"
                slot-scope="{ row,index }">
        <Checkbox @on-change="onSelectionChange"
                  v-model="data[index].isCheck"></Checkbox>
      </template>
      <template slot="name"
                slot-scope="{ row,index }">
        <div class="name">
          <FileIcon :file="row"
                    class="m-r-10"/>
          <span @click.stop="onRowDblclick(row)"
                v-show="!row.isRename">{{row.name}}</span>
          <div class="rename"
               v-show="row.isRename">
            <Input :ref="'input'+index"
                   @on-enter="onClickRenameOk(index)"
                   @on-focus="onFocus($event)"
                   v-model="data[index].newName"/>
            <img @click.stop="onClickRenameOk(index)"
                 src="../../assets/common/addfolder_icon_ok.png">
            <img @click.stop="onClickRenameCancle(index)"
                 src="../../assets/common/addfolder_icon_cancle.png">
          </div>
        </div>
      </template>
      <template slot="tag"
                slot-scope="{ row,index }">
        <div class="tag">
          <Dropdown @on-click="(key)=>{onClickTag(key,row,index)}"
                    trigger="click"
                    v-if="row.isSign===1">
            <Icon color="#FF6A67"
                  custom="i-icon icon-tags-fulling"
                  size="20"
                  title="标签"/>
            <DropdownMenu slot="list">
              <DropdownItem name="look">查看标签</DropdownItem>
              <DropdownItem name="add">添加标签</DropdownItem>
              <DropdownItem name="delete">删除标签</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Icon @click.stop="onClickTag('add',row)"
                color="#2D8CF0"
                custom="i-icon icon-add"
                size="20"
                title="标签"
                v-else/>
        </div>
      </template>
      <template slot="operation"
                slot-scope="{ row,index }">
        <slot :index="index"
              :row="row"
              name="operation"></slot>
      </template>
    </Table>
  </div>
</template>

<script>
import FileIcon from '../../components/common/FileIcon'

export default {
  props: {
    columns: Array,
    data: Array,
    showHeader: {
      type: Boolean,
      default: true
    },
    sortKey: String,
    sortType: String
  },
  components: {FileIcon},
  data () {
    return {
      selectArr: []
    }
  },
  computed: {
    // 是否全选
    isCheckAll () {
      if (this.selectArr.length === 0) {
        return false
      } else {
        return this.selectArr.length === this.data.length
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      // 自定义多选框
      for (const i in this.columns) {
        let item = this.columns[i]
        if (item.type === 'selection') {
          this.$delete(item, 'type')
          this.$set(item, 'slot', 'checkbox')
          this.$set(item, 'renderHeader', (h, params) => {
            return h('Checkbox', {
              props: {
                value: this.isCheckAll,
              },
              on: {
                'on-change': this.onChangeCheckAll
              }
            })
          })
        }
        if (item.sortable === 'custom') {
          this.$delete(item, 'sortable')
          this.$set(item, 'renderHeader', (h, params) => {
            if (item.key === this.sortKey) {
              return h('p', {
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer'
                },
                on: {
                  click: () => {
                    this.onSortChange(item)
                  }
                }
              }, [
                h('span', {
                  style: {
                    marginRight: '10px'
                  }
                }, item.title),
                h('Icon', {
                  props: {
                    custom: 'i-icon ' + (this.sortType === 'asc' ? 'icon-sort-ascending' : 'icon-sort-descending'),
                    size: '16'
                  }
                })
              ])
            } else {
              return h('span', {
                style: {
                  cursor: 'pointer'
                },
                on: {
                  click: () => {
                    this.onSortChange(item)
                  }
                }
              }, item.title)
            }
          })
        }
      }
    },
    // 修改全选
    onChangeCheckAll (boo) {
      let selectArr = []
      if (boo) {
        selectArr = this.data.map(item => item)
      } else {
        selectArr = []
      }
      this.selectArr = selectArr
      this.$emit('on-selection-change', selectArr)
      this.data.forEach(item => {
        item.isCheck = boo
      })
    },
    /**
     * 重置选择状态
     */
    resetSelectArr () {
      this.selectArr = []
    },
    // 修改单选
    onSelectionChange () {
      let selectArr = []
      this.data.forEach(item => {
        if (item.isCheck) {
          selectArr.push(item)
        }
      })
      this.selectArr = selectArr
      this.$emit('on-selection-change', selectArr)
    },
    // 双击某一行
    onRowDblclick (row) {
      this.$emit('on-row-dblclick', row)
    },
    // 排序
    onSortChange (item) {
      let sortKey = this.sortKey
      let sortType = this.sortType
      if (item.key === sortKey) {
        if (sortType === 'asc') {
          sortType = 'desc'
        } else {
          sortType = 'asc'
        }
      } else {
        sortKey = item.key
        sortType = 'asc'
      }
      let obj = {
        key: sortKey,
        order: sortType
      }
      this.$emit('on-sort-change', obj)
    },
    // 点击重命名
    onClickRename (item, index) {
      item.isRename = true
      this.$nextTick(() => {
        this.$refs['input' + index].focus()
      })
    },
    // input聚焦
    onFocus (e) {
      // let value = e.target.value
      // let length = value.length
      // lastIndexOf('.')
      // if (length === -1) {
      e.target.select()
      // } else {
      //   e.target.setSelectionRange(0, length)
      // }
    },
    // 点击确定重命名
    onClickRenameOk (index) {
      let item = this.data[index]
      if (item.objType === 2 && item.folderId === 0) {
        let reg = /[\[\]\^%\<>\\|*?,{}/]/
        if (reg.test(item.newName)) {
          return this.$Message.info('文件名不能包含以下字符：[,],%,<,>,,|,*,?,,/,{,}')
        }
        this.data.splice(index, 1)
        if (item.newName === '') {
          return this.$Message.info('文件名不能为空')
        }
        this.$emit('on-create-folder', item)
      } else {
        let reg = /[\[\]\^%\<>\\|*?,{}/]/
        if (reg.test(item.newName)) {
          item.isRename = false
          item.newName = item.name
          return this.$Message.info('文件名不能包含以下字符：[,],%,<,>,\,|,*,?,,/,{,}')
        }
        if (item.newName === '') {
          item.isRename = false
          item.newName = item.name
          return this.$Message.info('文件名不能为空')
        }
        if (item.name === item.newName) {
          item.isRename = false
          return
        }
        this.$emit('on-rename', item)
      }
    },
    // 点击取消重命名
    onClickRenameCancle (index) {
      let item = this.data[index]
      if (item.objType === 2 && item.folderId === 0) {
        this.data.splice(index, 1)
      } else {
        let item = this.data[index]
        item.isRename = false
        let editName = item.name
        let lengthindex = item.name.lastIndexOf('.')
        if (lengthindex > -1) {
          editName = editName.substr(0, lengthindex)
        }
        item.newName = editName
      }
    },
    // 点击标签
    onClickTag (key, row, index) {
      switch (key) {
        case 'look':
          this.$emit('on-click-lookTag', row)
          break
        case 'add':
          this.$emit('on-click-addTag', row)
          break
        case 'delete':
          this.$emit('on-click-deleteTag', row)
          break
      }
    }
  }
}

</script>
<style lang='scss' scoped>
  .selectHeader {
    height: 39px;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding-left: 10px;
    vertical-align: middle;
    border-bottom: 1px solid #e8eaec;
    position: sticky;
    top: 0;
    z-index: 10;

    span {
      font-size: 14px;
      color: #999;
      margin-left: 12px;
    }
  }

  .table {
    overflow: visible;

    ::v-deep .ivu-table {
      overflow: visible;

      .ivu-table-header {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: #fff;

        th {
          background-color: #fff;
        }
      }

      .ivu-table-cell {
        padding: 0;
        padding-right: 10px;

        .ivu-checkbox-wrapper {
          margin: 0;
        }
      }

      /* 展开区域 */
      .ivu-table-cell-with-expand {
        display: none;
      }

      .ivu-table-expanded-cell {
        background-color: #fff;
        padding: 10px 40px;
      }
    }

    .name {
      display: flex;
      align-items: center;

      img {
        width: 26px;
        height: 26px;
      }

      span {
        cursor: pointer;
      }

      .rename {
        display: flex;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }

    .tag {
      display: flex;
      align-items: center;

      ::v-deep .ivu-dropdown-rel {
        display: flex;
        align-items: center;
      }

      ::v-deep .ivu-icon {
        cursor: pointer;
      }
    }
  }
</style>
