var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showHeader&&_vm.selectArr.length>0)?_c('div',{staticClass:"selectHeader"},[_c('Checkbox',{attrs:{"disabled":_vm.data.length===0,"value":_vm.isCheckAll},on:{"on-change":_vm.onChangeCheckAll}}),_c('span',[_vm._v("已选中"+_vm._s(_vm.selectArr.length)+"项/共"+_vm._s(_vm.data.length)+"项")])],1):_vm._e(),_c('Table',{ref:"table",staticClass:"table",attrs:{"columns":_vm.columns,"data":_vm.data,"show-header":_vm.showHeader&&_vm.selectArr.length===0,"highlight-row":""},on:{"on-row-dblclick":_vm.onRowDblclick},scopedSlots:_vm._u([{key:"checkbox",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Checkbox',{on:{"on-change":_vm.onSelectionChange},model:{value:(_vm.data[index].isCheck),callback:function ($$v) {_vm.$set(_vm.data[index], "isCheck", $$v)},expression:"data[index].isCheck"}})]}},{key:"name",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"name"},[_c('FileIcon',{staticClass:"m-r-10",attrs:{"file":row}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!row.isRename),expression:"!row.isRename"}],on:{"click":function($event){$event.stopPropagation();return _vm.onRowDblclick(row)}}},[_vm._v(_vm._s(row.name))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(row.isRename),expression:"row.isRename"}],staticClass:"rename"},[_c('Input',{ref:'input'+index,on:{"on-enter":function($event){return _vm.onClickRenameOk(index)},"on-focus":function($event){return _vm.onFocus($event)}},model:{value:(_vm.data[index].newName),callback:function ($$v) {_vm.$set(_vm.data[index], "newName", $$v)},expression:"data[index].newName"}}),_c('img',{attrs:{"src":require("../../assets/common/addfolder_icon_ok.png")},on:{"click":function($event){$event.stopPropagation();return _vm.onClickRenameOk(index)}}}),_c('img',{attrs:{"src":require("../../assets/common/addfolder_icon_cancle.png")},on:{"click":function($event){$event.stopPropagation();return _vm.onClickRenameCancle(index)}}})],1)],1)]}},{key:"tag",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"tag"},[(row.isSign===1)?_c('Dropdown',{attrs:{"trigger":"click"},on:{"on-click":function (key){_vm.onClickTag(key,row,index)}}},[_c('Icon',{attrs:{"color":"#FF6A67","custom":"i-icon icon-tags-fulling","size":"20","title":"标签"}}),_c('DropdownMenu',{attrs:{"slot":"list"},slot:"list"},[_c('DropdownItem',{attrs:{"name":"look"}},[_vm._v("查看标签")]),_c('DropdownItem',{attrs:{"name":"add"}},[_vm._v("添加标签")]),_c('DropdownItem',{attrs:{"name":"delete"}},[_vm._v("删除标签")])],1)],1):_c('Icon',{attrs:{"color":"#2D8CF0","custom":"i-icon icon-add","size":"20","title":"标签"},on:{"click":function($event){$event.stopPropagation();return _vm.onClickTag('add',row)}}})],1)]}},{key:"operation",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_vm._t("operation",null,{"index":index,"row":row})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }